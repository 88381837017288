<template>
    <div id="content">
    
        <div class="card" id="information">
            <h3 class="card_title">Stammdaten</h3>
            <div class="padding">
                <div id="dataGrid" class="box">
                    <p><b>Kalkulation:</b></p>
                    <input type="text" :value="information.name" @input="changeValue('name', $event.target.value)">
                </div>

                <div class="box">
                    <p class="textspacing"><b>Stammdaten:</b></p>
                    <div id="dataGrid" class="indent">
                        <p>Bauteilname:</p>
                        <input type="text" :value="information.part" @input="changeValue('part', $event.target.value)">

                        <p>Kunde:</p>                        
                        <input type="text" :value="customerInformation.name" @input="changeCustomerInformation('name', $event.target.value)">

                        <p>Losgröße:</p>                        
                        <input type="number" min="1" :value="information.lotsize" @input="changeValue('lotsize', $event.target.value)">

                        <p>Lieferdatum:</p>                        
                        <input class="radius_small" type="date" :value="information.deadline" @input="changeValue('deadline', $event.target.value)">

                        <p>Beschreibung:</p>                        
                        <textarea class="radius_small" :value="information.description" @input="changeValue('description', $event.target.value)"></textarea>
                    </div>
                    <div v-for="(d, i) in $store.state.calculation.information.ownData" :key="i">
                        <p>
                            <input type="text" placeholder="Name" v-model="d.name">
                            <img @click="removeOwnData(i)" class="invert pointer" src="https://dashboard.pixelprime.de/assets/icons/light/decline.svg" alt="">
                        </p>
                        <input type="text" placeholder="Information" v-model="d.value">
                    </div>
                    <!-- <p class="dark radius padding_small pointer fitContent" @click="addOwnData()">Datensatz anlegen</p> -->
                </div>
            </div>
        </div>

        <div class="card">
            <h3 class="card_title">PDF Export</h3>
            <div class="padding">
                <div class="box">
                    <p><b>Kunde:</b></p>
                    <div class="exportGrid indent">
                        <p>Name:</p>
                        <input type="text" :value="customerInformation.name" @input="changeCustomerInformation('name', $event.target.value)">

                        <p>Straße:</p>
                        <input type="text" :value="customerInformation.street" @input="changeCustomerInformation('street', $event.target.value)">

                        <p>Hausnummer:</p>
                        <input type="text" :value="customerInformation.housenumber" @input="changeCustomerInformation('housenumber', $event.target.value)">

                        <p>Plz, Ort:</p>
                        <input type="text" :value="customerInformation.zipcodecity" @input="changeCustomerInformation('zipcodecity', $event.target.value)">
                    </div>
                </div>
                <div class="box">
                    <p><b>Preise nach Losgrößen:</b></p>
                    <div id="lotsizes" class="indent lotsizeGrid" v-for="(lotsize, l) in lotsizes" :key="l">
                        <input :id="'lotsize_'+l" style="width: 5em" type="number" :value="lotsize" @input="changeLotsize(l, $event.target.value)">
                        <input type="number" :value="lotsize_prices[lotsize]" @input="changeLotsizePrice(lotsize, $event.target.value)">
                        <p>€</p>
                    </div>
                    <div class="lotsizeGrid indent" v-if="lotsizes.length < 10">
                        <input placeholder="+ Neu" style="width: 5em" type="number" :value="newValue" @input="newLotsize($event.target.value)">
                        <p></p>
                    </div>
                </div>
                <div class="box">
                    <p><b>Weitere Informationen:</b></p>
                    <div class="lotsizeGrid indent">
                        <p>Gültigkeitsdauer:</p>
                        <input type="date" :value="customerInformation.offerValidity" @input="changeCustomerInformation('offerValidity', $event.target.value)">
                        <p></p>
                        <!-- 
                        <p>Rabatt:</p>
                        <input type="number" :value="other.discount" @input="changeOtherValue('discount', $event.target.value)">
                        <p>%</p> -->
                        
                        <p>Skonto:</p>
                        <input type="number" :value="other.cashdiscount" @input="changeOtherValue('cashdiscount', $event.target.value)">
                        <p>%</p>
                        <p class="indent" v-if="other.cashdiscount != 0">Skontofrist:</p>
                        <input v-if="other.cashdiscount != 0" type="number" :value="other.cashdiscount_days" @input="changeOtherValue('cashdiscount_days', $event.target.value)">
                        <p v-if="other.cashdiscount != 0">Tage</p>
                    </div>
                </div>
                <div class="box">
                    <p><b>Text:</b></p>
                    <div id="textBox" class="indent">
                        <p>Anschreiben:</p>
                        <textarea :value="customerInformation.lettertext" @input="changeCustomerInformation('lettertext', $event.target.value)" rows="4"></textarea>
                        <p>Schlusstext:</p>
                        <textarea :value="customerInformation.summarytext" @input="changeCustomerInformation('summarytext', $event.target.value)" rows="4"></textarea>
                    </div>
                </div>
                
                <div class="box">
                    <p class="padding_small pointer radius blue indent fitContent" @click="$store.commit('exportPDF', exportType)">
                        Exportieren
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "file",
    data() {
        return {
            lotsize_prices: {},
            // lotsizes: [1,50,250],
            customLotsizes: {},
            newValue: "",
            exportType: "offer"
        }
    },
    mounted() {
        this.refreshLotsizes()
    },
    methods: {
        changeOtherValue(key, value) {
            this.$store.commit("setOtherValue", [key, value]);
            this.$store.dispatch("refresh")
            this.refreshLotsizes()
        },
        changeLotsize(i, value) {
            this.$store.commit("lotsize_change", [i, value]);
            this.refreshLotsizes()
        },
        changeLotsizePrice(lotsize, price) {
            this.customLotsizes[lotsize] = 1
            this.lotsize_prices[lotsize] = price
        },
        newLotsize(value) {
            this.$store.commit("lotsize_add", parseInt(value));
            // this.lotsizes.push(value)
            setTimeout(() => {
                document.getElementById("lotsize_"+(this.lotsizes.length-1)).focus()
            }, 50);
            this.newValue = ""
            this.refreshLotsizes()
            this.$forceUpdate();
        },
        refreshLotsizes() {
            let originalLotsize = this.information.lotsize
            this.lotsizes.forEach(lotsize => {
                if(this.customLotsizes[lotsize]) return
                this.$store.commit("information_set", ["lotsize", lotsize])
                this.$store.dispatch("calculate")
                this.lotsize_prices[lotsize] = this.$store.getters.calcInfo.cost.piece
            });
            this.$store.state.lotsize_prices = this.lotsize_prices
            this.$store.commit("information_set", ["lotsize", originalLotsize])
            this.$store.dispatch("calculate")
        },
        changeValue(key, value) {
            this.$store.commit("information_set", [key, value])
            this.$store.dispatch("calculate")
        },
        changeCustomerInformation(key, value) {
            this.$store.commit("setCustomerInformation", [this.$store.state, key, value])
        }
    },
    computed: {
        information() {
            return this.$store.getters.information
        },
        lotsizes() {
            console.log(this.$store.getters.lotsize);
            return this.$store.getters.lotsizes
        },
        customerInformation() {
            return this.$store.getters.customerInformation
        },
        other() {
            let values = this.$store.state.calculation.other
            if(!values.individual)values.individual = 0
            if(!values.overhead)values.overhead = 0
            if(!values.discount)values.discount = 0
            if(!values.cashdiscount)values.cashdiscount = 0
            return values
        },
    }
}
</script>

<style scoped>
    input, textarea{
        border: none;
    }
    /* #information>div>div:nth-child(odd){
        background-color: rgb(250, 250, 250);
    } */
    #dataGrid{
        margin-bottom: 4px;
    }
    .exportGrid{
        display: grid;
        grid-gap: 4px 0em;
        grid-template-columns: 12em 16em;
    }
    .lotsizeGrid{
        display: grid;
        grid-gap: 4px 0em;
        grid-template-columns: 12em 8em 1em;
        }
    #lotsizes{
        margin-bottom: 4px;
        grid-template-columns: 12em 5em 1em auto;        
    }
    #lotsizeGrid{
        grid-template-columns: auto auto 2em!important;
        grid-column-gap: .5em!important;
        margin-bottom: 4px;
    }
    #dataGrid{
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 1em;
        grid-row-gap: 4px;
    }
    #dataGrid>p{
        display: grid;
        grid-template-columns: auto auto;
    }
    #dataGrid>p>input{width: 100%;}
    #dataGrid>input{
        border: none;
        outline: hidden;
    }
    #textBox>textarea{
        width: calc(100% - 6px);
        resize: vertical;
    }
    
</style>